<template>
  <div>
    <b-row class="d-flex justify-content-end">
      <b-col cols="5">
        <b-card no-body>
          <!-- title and dropdown -->
          <b-card-header class="pb-0">
            <b-card-title>{{ trackerData.title }}f {{ period }}</b-card-title>
            <b-dropdown
              no-caret
              right
              text="Last 7 Days"
              variant="transparent"
              class="chart-dropdown"
              toggle-class="p-50"
              size="sm"
            >
              <b-dropdown-item
                v-for="day in ['week','month','3 months', 'year']"
                :key="day"
                v-model="period"
              >
                last {{ day }}
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>
          <!--/ title and dropdown -->

          <b-card-body>
            <b-row>
              <!-- chart -->
              <b-col
                sm="10"
                class="d-flex justify-content-center"
              >

                <!-- apex chart -->
                <vue-apex-charts
                  type="radialBar"
                  height="270"
                  :options="supportTrackerRadialBar.chartOptions"
                  :series="supportTrackerRadialBar.series"
                />
              </b-col>
            <!--/ chart -->
            </b-row>

          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BRow,
    BCol,
  },
  data() {
    return {
      period: '',
      trackerData: {},
      supportTrackerRadialBar: {
        series: [83],
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Completed Tickets'],
        },
      },
    }
  },
  created() {
    this.$http.get('/card/card-analytics/support-tracker').then(res => { this.trackerData = res.data })
  },
}
</script>
