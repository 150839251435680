<template>
  <div>
    <visitor-tracker v-if="false" />
    <analytics-card />
  </div>
</template>

<script>

import VisitorTracker from '@/views/components/analytics/VisitorTracker.vue'
import AnalyticsCard from '@/views/components/analytics/AnalyticsCard.vue'

export default {
  components: {
    AnalyticsCard,
    VisitorTracker,
  },
  date() {
    return {
      analytics: [],
    }
  },
}
</script>

<style>

</style>
